.main-wrapper.is-flex {
  display: flex;
  min-height: 100svh;
  flex-direction: column;
}

.section_filter.background-color-default {
  flex: 1;
}

.background-color-default {
  background-color: var(--colorGray100-fcffe448);
}

.padding-global {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.page-container {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  padding-bottom: 8rem;
}

@media screen and (max-width: 767px) {
  .page-container {
    padding-top: 1.25rem;
  }
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}