@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600;700&family=Inter:wght@400;600&display=swap');

#attachments-app .paired-wrapper {
    background-color: transparent;
    padding: 76px;
}

#attachments-app .paired-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    width: 100%;
}

#attachments-app .paired-title {
    color: #171718;
    font-family: Barlow;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 32px;
}

@media screen and (max-width: 767px) {

    #attachments-app .paired-wrapper {
    padding: 12px;
    }

    #attachments-app .paired-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 32px;
    width: 100%;
    }
}