.filter-card {
  position: relative;

  display: flex;
  min-height: 7.5rem;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  border: 2px solid var(--colorGray200-13fd707b);
  border-radius: 8px;

  background-color: var(--colorWhite-d32730aa);

  transition: all 200ms ease;

  color: var(--colorGray500-463b4481);
  text-align: center;

  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .filter-card {
    min-height: 5rem;
    padding: 1rem;
    flex-direction: row;
  }
}

.filter-card.is-selected {
  border-color: var(--colorBrand400-7bfbbcf3);
}

.filter-card_image {
  width: 100%;
  max-height: 14rem;
  min-height: 4rem;
  border-radius: 4px;
  object-fit: contain;
}

@media screen and (max-width: 767px) {
  .filter-card_image {
    max-height: none;
    max-width: 5rem;
    min-height: auto;
  }
}

.filter-card_content {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-direction: column;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

@media screen and (max-width: 767px) {
  .filter-card_content {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    flex: 1;
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;

    text-align: left;
  }
}

.filter-card_title {
  color: var(--colorGray900-69a43f59);
  font-weight: 600;
}

.filter-card_description {
  color: var(--colorGray500-463b4481);
  font-size: 0.875rem;
}