
#attachments-app {
:root {
  font-size: 16px;

  /* Breakpoints 
    ---------------------------------------------
    */
  --breakpoint-tablet: 991px;
  --breakpoint-mobile: 767px;

  /* Global colors 
    ---------------------------------------------
    */
  --color-white: #fff;
  --color-black: #000;

  /* Gray */
  --color-gray-50: #fafafa;
  --color-gray-100: #f5f5f5;
  --color-gray-200: #e5e5e5;
  --color-gray-300: #d4d4d4;
  --color-gray-400: #a3a3a3;
  --color-gray-500: #737373;
  --color-gray-600: #525252;
  --color-gray-700: #404040;
  --color-gray-800: #262626;
  --color-gray-900: #171717;

  /* Brand */
  --color-brand-50: #fffbf0;
  --color-brand-100: #ffecc0;
  --color-brand-200: #ffdb90;
  --color-brand-300: #ffc860;
  --color-brand-400: #ffb230;
  --color-brand-500: #ff9900;
  --color-brand-600: #d67500;
  --color-brand-700: #ac5500;
  --color-brand-800: #833a00;
  --color-brand-900: #5a2300;

  /* Blue */
  --color-blue-50: #f2f7fe;
  --color-blue-100: #c8ddf9;
  --color-blue-200: #9fc6f4;
  --color-blue-300: #75b0ef;
  --color-blue-400: #4c9deb;
  --color-blue-500: #228be6;
  --color-blue-600: #1d7dc1;
  --color-blue-700: #176c9c;
  --color-blue-800: #125876;
  --color-blue-900: #0c4051;

  /* Green */
  --color-green-50: #f4fbf6;
  --color-green-100: #d0efd9;
  --color-green-200: #ace4ba;
  --color-green-300: #88d89a;
  --color-green-400: #64cc79;
  --color-green-500: #40c057;
  --color-green-600: #36a143;
  --color-green-700: #2b8232;
  --color-green-800: #216322;
  --color-green-900: #184417;

  /* Yellow */
  --color-yellow-50: #fffcf0;
  --color-yellow-100: #fef2c1;
  --color-yellow-200: #fde592;
  --color-yellow-300: #fcd663;
  --color-yellow-400: #fbc434;
  --color-yellow-500: #fab005;
  --color-yellow-600: #d28904;
  --color-yellow-700: #a96503;
  --color-yellow-800: #814603;
  --color-yellow-900: #582c02;

  /* Red */
  --color-red-50: #fff6f5;
  --color-red-100: #fed9d4;
  --color-red-200: #fdb9b4;
  --color-red-300: #fc9993;
  --color-red-400: #fb7673;
  --color-red-500: #fa5252;
  --color-red-600: #d2454c;
  --color-red-700: #a93744;
  --color-red-800: #812a38;
  --color-red-900: #581d2a;
}}

#attachments-app {
body {
  font-family: "Inter", sans-serif;
  color: var(--color-gray-600);
}}
/* HTML Heading Tags */
#attachments-app {
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: var(--color-gray-900);
  line-height: 1.2;
}}

#attachments-app {
h1 {
  font-size: 3.75rem;
}}

#attachments-app {
h2 {
  font-size: 3rem;
}}

#attachments-app {
h3 {
  font-size: 2.25rem;
}}

#attachments-app {
h4 {
  font-size: 1.875rem;
}}

#attachments-app {
h5 {
  font-size: 1.5rem;
}}

#attachments-app {
h6 {
  font-size: 1.25rem;
}}

/* Other HTML tags */
#attachments-app {
a {
  color: var(--color-blue-500);
  transition: all 0.3s;
}}

#attachments-app {
a:hover {
  color: var(--color-blue-400);
}}

#attachments-app {
a:visited {
  color: var(--color-blue-800);
}}

#attachments-app {
p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-gray-600);
}}

#attachments-app {
  .mark-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }}

@media screen and (max-width: 767px) {
  #attachments-app {
    .mark-button-container {
      display: flex;
      flex-direction: column-reverse;
      }}
  }

/* Heading Styles */
#attachments-app {
.heading-style-h1,
.heading-style-h2,
.heading-style-h3,
.heading-style-h4,
.heading-style-h5,
.heading-style-h6 {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}}

#attachments-app {
.heading-style-h1 {
  font-size: 3.75rem;
}}

#attachments-app {
.heading-style-h2 {
  font-size: 3rem;
}}

#attachments-app {
.heading-style-h3 {
  font-size: 2.25rem;
}}

#attachments-app {
.heading-style-h4 {
  font-size: 2rem;
}}

#attachments-app {
.heading-style-h5 {
  font-size: 1.5rem;
}}

#attachments-app {
.heading-style-h6 {
  font-size: 1rem;
}}

/* Text Classes */
/* Text Sizes */

#attachments-app {
.text-size-large {
  font-size: 1.25rem;
}}

#attachments-app {
.text-size-medium {
  font-size: 1.125rem;
}}

#attachments-app {
.text-size-regular {
  font-size: 1rem;
}}

#attachments-app {
.text-size-small {
  font-size: 0.875rem;
}}

#attachments-app {
.text-size-tiny {
  font-size: 0.75rem;
}}

/* Text Styles */
#attachments-app {
.text-style-strikethrough {
  text-decoration: line-through;
}}

#attachments-app {
.text-style-italic {
  font-style: italic;
}}

#attachments-app {
.text-style-muted {
  opacity: 0.6;
}}

#attachments-app {
.text-style-allcaps {
  text-transform: uppercase;
}}

#attachments-app {
.text-style-nowrap {
  white-space: nowrap;
}}

#attachments-app {
.text-style-link {
  text-decoration: underline;
}}

#attachments-app {
.text-style-quote {
  margin-bottom: 0rem;
  padding-top: 0rem;
  padding-right: 1.25rem;
  padding-bottom: 0rem;
  padding-left: 1.25rem;
  border-left-style: solid;
  border-left-width: 0.25rem;
  border-left-color: var(--color-gray-200);
  font-size: 1.25rem;
  line-height: 1.5;
}}

#attachments-app {
.text-style-label {
  font-family: "Barlow", sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}}

/* Text Weights */
#attachments-app {
.text-weight-xbold {
  font-weight: 700;
}}

#attachments-app {
.text-weight-bold {
  font-weight: 700;
}}

#attachments-app {
.text-weight-semibold {
  font-weight: 600;
}}

#attachments-app {
.text-weight-medium {
  font-weight: 500;
}}

#attachments-app {
.text-weight-normal {
  font-weight: 400;
}}

#attachments-app {
.text-weight-light {
  font-weight: 400;
}}

/* Text Alignments */
#attachments-app {
.text-align-left {
  text-align: left;
}}

#attachments-app {
.text-align-center {
  text-align: center;
}}

#attachments-app {
.text-align-right {
  text-align: right;
}}

/* Text Colors */
#attachments-app {
.text-color-default {
  color: var(--color-gray-900);
}}

#attachments-app {
.text-color-weak {
  color: var(--color-gray-600);
}}

#attachments-app {
.text-color-weaker {
  color: var(--color-gray-500);
}}

#attachments-app {
.text-color-inverse {
  color: var(--color-white);
}}

#attachments-app {
.text-color-inverse-weak {
  color: var(--color-gray-300);
}}

#attachments-app {
.text-color-inverse-weaker {
  color: var(--color-gray-400);
}}

#attachments-app {
.text-color-error {
  color: var(--color-red-500);
}}

#attachments-app {
.text-color-brand {
  color: var(--color-brand-600);
}}

/* Background Colors */
#attachments-app {
.background-color-surface {
  background-color: var(--color-white);
}}

#attachments-app {
.background-color-raised {
  background-color: var(--color-gray-50);
}}

/* Tablet CSS */

@media screen and (max-width: var(--breakpoint-tablet)) {
}

/* Mobile CSS */

@media screen and (max-width: var(--breakpoint-mobile)) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  .heading-style-h1 {
    font-size: 2.5rem;
  }

  .heading-style-h2 {
    font-size: 2rem;
  }

  .heading-style-h3 {
    font-size: 2rem;
  }

  .heading-style-h4 {
    font-size: 1.5rem;
  }

  .heading-style-h5 {
    font-size: 1.125rem;
  }

  .heading-style-h6 {
    font-size: 1rem;
  } 
}
#attachments-app {
.container {
  height: 100%;
  background-color: #f6f5f5;;
}}


#attachments-app {
.form-wrapper {
  max-width: 40rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5rem;
  border-radius: 8px;
  background-color: var(--colorWhite-d32730aa);
  /* box-shadow: 0 25px 50px 0 hsla(0, 0.00%, 0.00%, 0.25); */
}}

#attachments-app {
.footer-stick {
  position: relative;
  margin-top: 10rem;
  left: 0;
  bottom: 0;
  width: 100%;
}}

#attachments-app {
.form-container {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}}

#attachments-app {
.input-container {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}}

#attachments-app {
.input-field {
  border-radius: 4px;
  border: 2px solid var(--color-gray-300, #D4D4D4);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

}}

#attachments-app {
.input-error {
  position: absolute;
  left: 0;
  top: 100%;
  min-height: 0.25rem;
  color: #FA5252; 
}}

#attachments-app {
.input-label {
  display: flex;
  flex-direction: row;
  gap: 6px;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  color: #171718;
  font-weight: 600;
  padding-left: 0;
}}

#attachments-app {
.btn-loading {
  min-height: 3.25rem;
  border: none;
  align-items: center;
  background: linear-gradient(90deg, #ffe299 0%, #ffba3f 50%, #ffe299 100%);
  background-size: 300%;
  -webkit-animation: loading-bg 4s linear infinite;
  animation: loading-bg 4s linear infinite;
  cursor: not-allowed;
}}

#attachments-app {
.btn {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.375rem;
  border-width: 2px;
  border-style: solid;
  padding: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: var(--neutral-900);
  transition: all 0.2s ease;
}}

#attachments-app {
.btn-primary {
  border-color: #FFBA3F;
  background-color: #FFBA3F;
}}

#attachments-app {
.btn-primary:hover {
  border-color: #FFCE66;
  background-color: #FFCE66;
}}

#attachments-app {
.btn-secondary {
  border-color: var(--neutral-900);
  transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
}}

#attachments-app {
.btn-secondary:hover {
  border-color: #FF9900;
  background-color: #FFFAEB;
  color: #522300;
}}

#attachments-app {
.button-container {
  display: flex;
  padding: 2rem;
  flex-direction: row;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  border-top: 1px solid var(--colorGray200-13fd707b);
}}

#attachments-app {
.option-select {
  display: block;
margin-left: auto;
margin-top: 1.25rem; /* Adjust the value as needed */
width: 100%;
background-color: #CBD5E0; /* Adjust the color as needed */
border-color: #1F2937; /* Adjust the color as needed */
border-width: 2px;
color: #000000; /* Adjust the color as needed */
padding: 1rem 2rem; /* Adjust the padding as needed */
border-radius: 0.375rem;
transition: transform 0.2s;
transform: scale(0.9);
}}

#attachments-app {
.option-other {
  display: block;
margin-left: auto;
margin-top: 1.25rem; /* Adjust the value as needed */
width: 100%;
background-color: #CBD5E0; /* Adjust the color as needed */
color: #000000; /* Adjust the color as needed */
padding: 1rem 2rem; /* Adjust the padding as needed */
border-radius: 0.375rem;
transition: transform 0.2s;
transform: scale(0.9);
}}

#attachments-app {
select {
  background-image:
	linear-gradient(45deg, transparent 50%, black 50%),
	linear-gradient(135deg, black 50%, transparent 50%),
	linear-gradient(to right, white, white);
  background-position:
	calc(100% - 20px) calc(50%),
	calc(100% - 15px) calc(50%),
	100% 0;
  background-size:
	5px 5px,
	5px 5px,
	2.5em 2.5em;
  background-repeat: no-repeat;
}}

#attachments-app {
input[type]:focus {
  border: 2px solid #228BE6;
}}

#attachments-app {
select:focus {
  border-color: #228BE6;
}}

#attachments-app {
.btn-upload {
  padding: 1rem;
  border: transparent;
  margin-top: 1rem;
}}

#attachments-app {
  .thank-container {
    width: 100%;
    max-width: 40rem;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid var(--colorGray200-13fd707b);
    border-radius: 8px;
    background-color: var(--colorWhite-d32730aa);
    display: flex;
    padding: 2rem;
    flex-direction: column;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    align-items: center;
  }}

  #attachments-app {
    .done-icon {
      display: flex;
      width: 100px;
      height: 100px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      background-color:#EBFBEE;
}}

.thank-text-block {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding-bottom: 40px;
}