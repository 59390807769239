.button {
  display: flex;
  height: 3rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  flex: 1;

  border-radius: 4px;

  background-color: var(--colorBrand400-7bfbbcf3);

  color: var(--colorGray900-69a43f59);
  line-height: 1.25;
  font-weight: 600;
  text-align: center;
}

.button:hover {
  background-color: var(--colorBrand300-09a44951);
  color: var(--colorGray900-69a43f59);
}

.button:active {
  background-color: var(--colorBrand500-87586c42);
}

.button.is-text {
  border: 2px solid transparent;
  background-color: transparent;
  color: var(--colorBlue500-867f037e);
}

.button.is-text:hover {
  color: var(--colorBlue300-816024cc);
}

.button.is-text:active {
  color: var(--colorBlue700-e02b2961);
}

.text-color-brand {
  color: var(--colorBrand600-329e046b);
}

.button.is-small {
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.button.is-form-submit {
  display: block;
  min-width: auto;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.button.is-large {
  height: 3.5rem;
  padding: 1rem 2rem;
}

.button.is-secondary {
  border: 2px solid var(--colorGray900-69a43f59);
  background-color: transparent;
  color: var(--colorGray900-69a43f59);
}

.button.is-secondary:hover {
  border-width: 2px;
  border-color: var(--colorBrand500-87586c42);
  background-color: var(--colorBrand50-b0219b0c);
}

.button.is-secondary:active {
  background-color: var(--colorBrand100-9d5f361a);
}

.button.is-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  text-decoration: none;
}

.button.is-disabled {
  background-color: var(--colorGray200-13fd707b);
  color: var(--colorGray400-ae40cf36);
  cursor: not-allowed;
}