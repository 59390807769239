#attachments-app .results-wrapper {
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr; */
    gap: 32px;
    width: 100%;
}

#attachments-app .results-modal {
    display: grid;
    flex-direction: column;
    background-color: transparent;
    padding-left: 75px;
    padding-right: 75px;
}

@media screen and (max-width: 767px) {
    #attachments-app .results-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        /* grid-template-rows: 1fr 1fr 1fr; */
        gap: 32px;
        width: 100%;
    }

    #attachments-app .results-modal {
        padding-left: 12px;
        padding-right: 12px;
    }
}