.result-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  flex: 1;

  border: 1px solid var(--colorGray200-13fd707b);
  border-radius: 8px;

  background-color: var(--colorWhite-d32730aa);
}

.result-card_recommended {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background-color: var(--colorBrand400-7bfbbcf3);
}

@media screen and (max-width: 479px) {
  .result-card_recommended {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.icon-1x1-default {
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 auto;
}

.text-style-label {
  font-family: Barlow;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.text-color-weaker {
  color: var(--colorGray500-463b4481);
}

.result-card_wrapper {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

@media screen and (max-width: 991px) {
  .result-card_wrapper {
    padding: 1rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}

@media screen and (max-width: 479px) {
  .result-card_wrapper {
    padding: 1rem;
  }
}

.result-card_image {
  width: 100%;
  height: 14rem;
  border-radius: 4px;
  object-fit: contain;
}

.result-card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  text-align: center;
}

@media screen and (max-width: 991px) {
  .result-card_content {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
  }
}

.result-card_title {
  display: flex;
  min-height: 3rem;
  align-items: center;

  font-family: Barlow;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

.result-card_category {
  display: flex;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.brand-label {
  color: var(--colorGray600-38f930db);
  font-size: 0.875rem;
  font-weight: 600;
}

.divider-vertical {
  width: 1px;
  align-self: stretch;
  background-color: var(--colorGray300-642ab057);
}

@media screen and (max-width: 479px) {
  .hide-mobile-portrait {
    display: none;
  }
}

.category-label {
  color: var(--colorGray500-463b4481);
  font-size: 0.875rem;
}

.text-size-medium {
  font-size: 1.125rem;
}

.text-weight-semibold {
  font-weight: 600;
}

.result-card_btn-wrapper {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-self: stretch;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  border-top: 1px solid var(--colorGray200-13fd707b);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  background-color: var(--colorWhite-d32730aa);
}

@media screen and (max-width: 991px) {
  .result-card_btn-wrapper {
    padding: 1rem;
  }
}

@media screen and (max-width: 479px) {
  .result-card_btn-wrapper {
    padding: 1rem;
  }
}

.button {
  display: flex;
  height: 3rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  flex: 1;

  border-radius: 4px;

  background-color: var(--colorBrand400-7bfbbcf3);

  color: var(--colorGray900-69a43f59);
  line-height: 1.25;
  font-weight: 600;
  text-align: center;
}

.button:hover {
  background-color: var(--colorBrand300-09a44951);
  color: var(--colorGray900-69a43f59);
}

.button:active {
  background-color: var(--colorBrand500-87586c42);
}

.button.is-text {
  border: 2px solid transparent;
  background-color: transparent;
  color: var(--colorBlue500-867f037e);
}

.button.is-text:hover {
  color: var(--colorBlue300-816024cc);
}

.button.is-text:active {
  color: var(--colorBlue700-e02b2961);
}

.text-color-brand {
  color: var(--colorBrand600-329e046b);
}

.button.is-small {
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.button.is-form-submit {
  display: block;
  min-width: auto;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.button.is-large {
  height: 3.5rem;
  padding: 1rem 2rem;
}

.button.is-secondary {
  border: 2px solid var(--colorGray900-69a43f59);
  background-color: transparent;
  color: var(--colorGray900-69a43f59);
}

.button.is-secondary:hover {
  border-width: 2px;
  border-color: var(--colorBrand500-87586c42);
  background-color: var(--colorBrand50-b0219b0c);
}

.button.is-secondary:active {
  background-color: var(--colorBrand100-9d5f361a);
}

.button.is-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  text-decoration: none;
}

.button.is-disabled {
  background-color: var(--colorGray200-13fd707b);
  color: var(--colorGray400-ae40cf36);
  cursor: not-allowed;
}

#w-node-b1a21a07-4a20-7567-70d2-62d268f21d22-68f21d22 {
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
  align-self: auto;
}