.simplified-footer {
  background-color: var(--colorGray100-fcffe448);
}

.padding-global {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.simplified-footer_wrapper {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media screen and (max-width: 479px) {
  .simplified-footer_wrapper {
    flex-direction: column;
  }
}

.simplified-footer_copyright {
  color: var(--colorGray500-463b4481);
  font-size: 0.875rem;
}

.divider-vertical {
  width: 1px;
  align-self: stretch;
  background-color: var(--colorGray300-642ab057);
}

@media screen and (max-width: 479px) {
  .hide-mobile-portrait {
    display: none;
  }
}

.simplified-footer_link {
  color: var(--colorGray500-463b4481);
  font-size: 0.875rem;
  font-weight: 400;
}

.simplified-footer_link:hover {
  color: var(--colorGray600-38f930db);
}

.simplified-footer_link:active {
  color: var(--colorGray700-25162d8d);
}