.filter-modal {
  width: 100%;
  max-width: 40rem;
  margin-right: auto;
  margin-left: auto;

  border: 1px solid var(--colorGray200-13fd707b);
  border-radius: 8px;

  background-color: var(--colorWhite-d32730aa);
}

.filter-modal_content-wrapper {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

@media screen and (max-width: 767px) {
  .filter-modal_content-wrapper {
    padding: 1.5rem;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
}

.filter-modal_content {
  display: flex;
  flex-direction: column;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.filter-modal_stepper {
  display: flex;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.text-style-label {
  font-family: Barlow;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.text-color-weaker {
  color: var(--colorGray500-463b4481);
}

.heading-style-h5 {
  flex: 1;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .heading-style-h5 {
    font-size: 1.125rem;
  }
}

.filter-modal_cards-wrapper {
  display: flex;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media screen and (max-width: 767px) {
  .filter-modal_cards-wrapper {
    flex-direction: column;
  }
}

.filter-modal_btn-wrapper {
  display: flex;
  padding: 2rem;
  flex-direction: row;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  border-top: 1px solid var(--colorGray200-13fd707b);
}

@media screen and (max-width: 767px) {
  .filter-modal_btn-wrapper {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 479px) {
  .filter-modal_btn-wrapper {
    flex-direction: column;
  }
}