.product-card {
  position: relative;

  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;

  border: 2px solid transparent;
  border-radius: 8px;

  background-color: var(--colorWhite-d32730aa);

  box-shadow: 0 1px 2px 0 hsla(0, 0.00%, 0.00%, 0.06), 0 1px 3px 0 hsla(0, 0.00%, 0.00%, 0.10);

  transition: all 200ms ease;

  color: var(--colorGray900-69a43f59);
}

.product-card:hover {
  border: 2px solid var(--colorBrand400-7bfbbcf3);
  box-shadow: 0 2px 4px 0 hsla(0, 0.00%, 0.00%, 0.06), 0 4px 6px 0 hsla(0, 0.00%, 0.00%, 0.10);
}

.product-card_details {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;

  display: flex;
  padding: 2rem 1.5rem 1.5rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;

  opacity: 0;
}

.product-card_details:hover {
  position: absolute;
  background-color: hsla(38.4375, 100.00%, 62.35%, 0.10);
  opacity: 1;
}

.product-card_hover {
  display: block;
  min-width: 14rem;
  padding: 1rem 1.5rem;
  flex: 0 auto;

  border: 2px solid var(--colorBrand400-7bfbbcf3);
  border-radius: 4px;

  background-color: var(--colorBrand400-7bfbbcf3);

  transition: all 200ms ease;

  color: var(--colorGray900-69a43f59);
  font-weight: 600;
  text-align: center;
}

.product-card_hover:hover {
  border-color: var(--colorBrand400-7bfbbcf3);
  background-color: var(--colorBrand400-7bfbbcf3);
}

.product-card_header {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
}

.product-card_title-wrapper {
  display: flex;
  flex-direction: column;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.product-card_brand {
  display: flex;
  align-self: stretch;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.brand-label {
  color: var(--colorGray600-38f930db);
  font-size: 0.875rem;
  font-weight: 600;
}

.divider-vertical {
  width: 1px;
  align-self: stretch;
  background-color: var(--colorGray300-642ab057);
}

@media screen and (max-width: 479px) {
  .hide-mobile-portrait {
    display: none;
  }
}

.category-label {
  color: var(--colorGray500-463b4481);
  font-size: 0.875rem;
}

.product-card_title {
  align-self: stretch;
  font-family: Barlow;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

.product-card_price-wrapper {
  display: flex;
  flex-direction: column;
  grid-column-gap: 0.125rem;
  grid-row-gap: 0.125rem;
}

.product-card_monthly-price {
  color: var(--colorGray500-463b4481);
  font-size: 0.875rem;
  text-align: right;
}

.product-card_price {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: right;
}

.product-card_image {
  width: 100%;
  height: 14rem;
  object-fit: contain;
}

.product-card_specs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.product-card_spec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
}

.product-card_spec-label {
  font-family: Barlow;
  color: var(--colorGray500-463b4481);
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.product-card_spec-value {
  font-size: 0.8125rem;
  font-weight: 600;
}

.text-color-weak {
  color: var(--colorGray600-38f930db);
}

.text-size-small {
  font-size: 0.875rem;
}

.product-card_button {
  display: block;
  min-width: 14rem;
  padding: 1rem 1.5rem;
  flex: 0 auto;

  border: 2px solid var(--colorGray900-69a43f59);
  border-radius: 4px;

  transition: all 200ms ease;

  font-weight: 600;
  text-align: center;
}

.product-card_button:hover {
  background-color: var(--colorBrand400-7bfbbcf3);
}

#w-node-_98d45e7d-98a4-d507-ce47-fbae430713eb-430713eb {
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
}
#w-node-_98d45e7d-98a4-d507-ce47-fbae430713f0-430713eb {
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
}
#w-node-_98d45e7d-98a4-d507-ce47-fbae430713f9-430713eb {
  grid-column-start: span 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  grid-row-end: span 1;
}