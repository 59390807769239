@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600;700&family=Inter:wght@400;600&display=swap');

#attachments-app .property-label {
  color: #171718;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

#attachments-app .row-property {
  color: #171718;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2px;
}

#attachments-app .col-title {
  padding: 1rem;
  text-align: left;
  color: #171718;
  font-family: "Barlow", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
}

#attachments-app .row-cells {
  padding: 1rem;
  border-right: 1px #d4d4d4;
}

#attachments-app .table-basic {
  width: 100%;
  overflow-x: auto;
  /* max-width: 1288px; */
  min-width: 850px;
}

#attachments-app .box-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

#attachments-app .divider {
  background-color: #d4d4d4;
  height: 1px;
}

#attachments-app .compare-modal {
  background-color: #FFFFFF;
  padding: 76px;
}

#attachments-app .compare-title {
  color: #171718;
  font-family: Barlow;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  #attachments-app .compare-modal {
      padding: 12px;
  }
}

::-webkit-scrollbar {
  height: 12px;
  background-color: #E5E5E5; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb {
  background-color: #A3A3A3;
  border-radius: 6px; /* Rounded corners for the thumb */
}
