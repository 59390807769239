@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600;700&family=Inter:wght@400;600&display=swap');
#attachments-app {
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}}

#attachments-app {
.loader {
    margin: 60px auto;
    width: 80px;
    height: 80px;
    border: 8px solid transparent;
    border-top: 8px solid #FFBA3F; /* Yellow */
    border-right: 8px solid #FFBA3F; /* Yellow */
    border-bottom: 8px solid #FFBA3F; /* Yellow */
    border-radius: 75%;
    animation: spin 2s linear infinite;
  }}

  #attachments-app {
  .title {
    color: #000000;
    font-family: "Barlow", Helvetica;
    font-weight: 700;
    line-height: 43.2px;
  }}
  
  #attachments-app {
  .description {
    color: #525351;
    font-family: "Inter", Helvetica;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
  }}
  
  #attachments-app {
  .loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }}

  @media screen and (max-width: 767px) {
    #attachments-app {
    .loader {
        margin: 35px auto;
        width: 60px;
        height: 60px;
        border: 8px solid transparent;
        border-top: 8px solid #FFBA3F; /* Yellow */
        border-right: 8px solid #FFBA3F; /* Yellow */
        border-bottom: 8px solid #FFBA3F; /* Yellow */
        border-radius: 75%;
        animation: spin 2s linear infinite;
      }}

    #attachments-app {
    .description {
        color: #525351;
        font-family: "Inter", Helvetica;
        font-weight: 400;
        line-height: 20px;
        margin-top: 14px;
      }}
      #attachments-app {
      .title {
        color: #000000;
        font-family: "Barlow", Helvetica;
        font-weight: 700;
        line-height: 43.2px;
      }}

  }