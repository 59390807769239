.section_simplified-header {
  border-bottom: 1px solid var(--colorGray200-13fd707b);
  background-color: var(--colorWhite-d32730aa);
}

.padding-global {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.simplified-header_wrapper {
  display: flex;
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  justify-content: space-between;
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.header-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.header-logo_desktop {
  display: flex;
  align-items: center;
  flex: 0 auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media screen and (max-width: 767px) {
  .header-logo_desktop {
    display: none;
  }
}

.header-logo {
  display: flex;
  height: 2rem;
  flex-direction: row;
}

@media screen and (max-width: 767px) {
  .hide-mobile-landscape {
    display: none;
  }
}

.divider-vertical {
  width: 1px;
  align-self: stretch;
  background-color: var(--colorGray300-642ab057);
}

@media screen and (max-width: 479px) {
  .hide-mobile-portrait {
    display: none;
  }
}

.header-title {
  display: flex;
  font-family: Barlow;
  font-size: 1.125rem;
  font-weight: 700;
}

.header-title.hide-mobile-portrait {
  flex-direction: row;
  flex: 1;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.header-logo_mobile {
  display: none;
  align-items: center;
  flex: 0 auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media screen and (max-width: 767px) {
  .header-logo_mobile {
    display: flex;
  }
}

.simplified-header_help-button {
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  align-self: center;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  border-radius: 4px;

  color: var(--colorGray500-463b4481);
}

.simplified-header_help-button:hover {
  background-color: var(--colorGray100-fcffe448);
  color: var(--colorGray500-463b4481);
}

.simplified-header_help-button:active {
  background-color: var(--colorGray200-13fd707b);
}

.icon-1x1-default {
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 auto;
}