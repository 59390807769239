.main-wrapper.is-flex {
  display: flex;
  min-height: 100svh;
  flex-direction: column;
}

.background-color-default {
  background-color: var(--colorGray100-fcffe448);
}

.padding-global {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.title-container {
  display: flex;
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-bottom: 2.5rem;
  margin-left: auto;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  flex-direction: column;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  border-bottom: 1px solid var(--colorGray200-13fd707b);
}

@media screen and (max-width: 767px) {
  .title-container {
    padding-top: 2rem;
  }
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.heading-style-h3 {
  font-size: 2.25rem;
  line-height: 1.2;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .heading-style-h3 {
    font-size: 2rem;
  }
}

.text-color-weak {
  color: var(--colorGray600-38f930db);
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.results-grid {
  display: flex;
  margin-bottom: 5rem;
  justify-content: center;
  align-items: flex-end;
  grid-auto-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}

@media screen and (max-width: 991px) {
  .results-grid {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .results-grid {
    margin-bottom: 3rem;
    flex-direction: column;
    align-items: stretch;
    grid-template-columns: 1fr;
  }
}

.compare-buttons {
  display: flex;
  max-width: 25rem;
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
  flex-direction: column;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media screen and (max-width: 767px) {
  .compare-buttons {
    margin-bottom: 3rem;
  }
}

.button {
  display: flex;
  height: 3rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  flex: 1;

  border-radius: 4px;

  background-color: var(--colorBrand400-7bfbbcf3);

  color: var(--colorGray900-69a43f59);
  line-height: 1.25;
  font-weight: 600;
  text-align: center;
}

.button:hover {
  background-color: var(--colorBrand300-09a44951);
  color: var(--colorGray900-69a43f59);
}

.button:active {
  background-color: var(--colorBrand500-87586c42);
}

.button.is-text {
  border: 2px solid transparent;
  background-color: transparent;
  color: var(--colorBlue500-867f037e);
}

.button.is-text:hover {
  color: var(--colorBlue300-816024cc);
}

.button.is-text:active {
  color: var(--colorBlue700-e02b2961);
}

.text-color-brand {
  color: var(--colorBrand600-329e046b);
}

.button.is-small {
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.button.is-form-submit {
  display: block;
  min-width: auto;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.button.is-large {
  height: 3.5rem;
  padding: 1rem 2rem;
}

.button.is-secondary {
  border: 2px solid var(--colorGray900-69a43f59);
  background-color: transparent;
  color: var(--colorGray900-69a43f59);
}

.button.is-secondary:hover {
  border-width: 2px;
  border-color: var(--colorBrand500-87586c42);
  background-color: var(--colorBrand50-b0219b0c);
}

.button.is-secondary:active {
  background-color: var(--colorBrand100-9d5f361a);
}

.button.is-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  text-decoration: none;
}

.button.is-disabled {
  background-color: var(--colorGray200-13fd707b);
  color: var(--colorGray400-ae40cf36);
  cursor: not-allowed;
}

.section_table {
  background-color: var(--colorWhite-d32730aa);
}

.table-container {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  padding-bottom: 8rem;
}

@media screen and (max-width: 767px) {
  .table-container {
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
}

.heading-style-h4 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .heading-style-h4 {
    font-size: 1.5rem;
  }
}

.table-slot {
  margin-top: 2rem;
}

.paried-container {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  padding-bottom: 8rem;
}

@media screen and (max-width: 767px) {
  .paried-container {
    padding-top: 3rem;
  }
}

.paired-with-grid {
  display: grid;
  margin-top: 2rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}

@media screen and (max-width: 991px) {
  .paired-with-grid {
    grid-template-columns: 1fr;
  }
}

#w-node-f54b1020-0100-c0b8-be6b-2a544a7d80c8-4a7d80b9 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}